
















import Drop from 'tether-drop';
import Vue from "vue";
import Component from "vue-class-component";
import {BButton} from "bootstrap-vue";

@Component({
  components: {
    BButton
  },
  props: {
    position: String,
    openOn: String,
    classes: String,
    constrainToWindow: Boolean,
    constrainToScrollParent: Boolean,
    hoverOpenDelay: Number,
    hoverCloseDelay: Number,
    focusDelay: Number,
    blurDelay: Number,
    tetherOptions: Object,
    remove: Boolean,
    header: String,
  }
})
export default class Tooltip extends Vue {

  drop: null | Drop = null;
  close: boolean = false;

  mounted() {
    this.drop = new Drop({
      target: this.$refs.dropTrigger as Element,
      content: this.$refs.dropContent as Element,
      position: this.$props.position ? this.$props.position : 'bottom left',
      openOn: this.$props.openOn ? this.$props.openOn : 'click',
      classes: this.$props.classes ? this.$props.classes : 'drop-theme-arrows',
      constrainToWindow: this.$props.constrainToWindow ? true : false,
      constrainToScrollParent: this.$props.constrainToScrollParent ? true : false,
      hoverOpenDelay: this.$props.hoverOpenDelay ? this.$props.hoverOpenDelay : 0,
      hoverCloseDelay: this.$props.hoverCloseDelay ? this.$props.hoverCloseDelay : 50,
      focusDelay: this.$props.focusDelay ? this.$props.focusDelay : 0,
      blurDelay: this.$props.blurDelay ? this.$props.blurDelay : 50,
      tetherOptions: this.$props.tetherOptions ? this.$props.tetherOptions : {},
      remove: this.close,

    });
  }
  
  onClose() {
    this.drop?.close();
    
    console.log("close" + this.close, this.drop);
  }
}
