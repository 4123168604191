var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var passes = ref.passes
          return [
            _c(
              "b-card",
              { attrs: { title: "Validate Claim" } },
              [
                _c(
                  "b-row",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Insurance Plan",
                              "label-for": "insurancePlan",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "insurancePlanId",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("infinit-select", {
                                          attrs: {
                                            id: "insurancePlan",
                                            includeIds: [
                                              _vm.formData.insurancePlanId,
                                            ],
                                            repo: _vm.insurancePlanRepo,
                                            label: "name",
                                          },
                                          model: {
                                            value: _vm.formData.insurancePlanId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "insurancePlanId",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "formData.insurancePlanId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Practice",
                              "label-for": "practice",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "practiceId", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("infinit-select", {
                                          attrs: {
                                            id: "practice",
                                            includeIds: [
                                              _vm.formData.practiceId,
                                            ],
                                            repo: _vm.practiceRepo,
                                            label: "name",
                                          },
                                          model: {
                                            value: _vm.formData.practiceId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "practiceId",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "formData.practiceId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: { label: "Profile", "label-for": "profile" },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "profileId", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("infinit-select", {
                                          attrs: {
                                            disabled: !_vm.formData.practiceId,
                                            id: "profile",
                                            includeIds: [
                                              _vm.formData.profileId,
                                            ],
                                            filter: {
                                              practiceId:
                                                _vm.formData.practiceId,
                                            },
                                            repo: _vm.profileRepo,
                                            label: "name",
                                          },
                                          model: {
                                            value: _vm.formData.profileId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "profileId",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "formData.profileId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("h4", [_vm._v("Patient info")]),
                _c(
                  "b-row",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: { label: "Patient", "label-for": "patient" },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "patientId", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("infinit-select", {
                                          attrs: {
                                            disabled: !_vm.formData.practiceId,
                                            id: "patient",
                                            includeIds: [
                                              _vm.formData.patientId,
                                            ],
                                            filter: {
                                              practiceId:
                                                _vm.formData.practiceId,
                                            },
                                            repo: _vm.patientRepo,
                                            label: "name",
                                          },
                                          model: {
                                            value: _vm.formData.patientId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "patientId",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "formData.patientId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("b-form-group", {
                          attrs: { label: "Gender" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var ariaDescribedby = ref.ariaDescribedby
                                  return [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "gender",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-radio-group", {
                                                  attrs: {
                                                    options: ["Male", "Female"],
                                                    "aria-describedby":
                                                      ariaDescribedby,
                                                  },
                                                  model: {
                                                    value: _vm.formData.gender,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "gender",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.gender",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("h4", [_vm._v("Visit info")]),
                _c(
                  "b-row",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Visit Date",
                              "label-for": "visitDate",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "visitDate", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-datepicker", {
                                          attrs: {
                                            id: "visitDate",
                                            type: "date",
                                            size: "sm",
                                          },
                                          model: {
                                            value: _vm.formData.visitDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "visitDate",
                                                $$v
                                              )
                                            },
                                            expression: "formData.visitDate",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "POS Code",
                              "label-for": "posCode",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "posCodeId", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("infinit-select", {
                                          attrs: {
                                            id: "provider",
                                            includeIds: [
                                              _vm.formData.posCodeId,
                                            ],
                                            repo: _vm.posRepo,
                                            label: "code",
                                          },
                                          model: {
                                            value: _vm.formData.posCodeId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "posCodeId",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "formData.posCodeId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Hospital Admission Date",
                              "label-for": "hospitalAdmissionDate",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "hospitalAdmissionDate",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-datepicker", {
                                          attrs: {
                                            id: "hospitalAdmissionDate",
                                            type: "date",
                                            size: "sm",
                                          },
                                          model: {
                                            value:
                                              _vm.formData
                                                .hospitalAdmissionDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "hospitalAdmissionDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.hospitalAdmissionDate",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("h4", [_vm._v("Authorization info")]),
                _c(
                  "b-row",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "b-col",
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "hasAuthorization",
                            rules: "required",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { id: "hasAuthorization" },
                                        model: {
                                          value: _vm.formData.hasAuthorization,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "hasAuthorization",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.hasAuthorization",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Patient has authorization\n          "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("validation-provider", {
                          attrs: {
                            name: "hasReferringDoctor",
                            rules: "required",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: { id: "hasReferringDoctor" },
                                        model: {
                                          value:
                                            _vm.formData.hasReferringDoctor,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "hasReferringDoctor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.hasReferringDoctor",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Has referring doctor\n          "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("h4", [_vm._v("Diagnosis")]),
                _c(
                  "b-row",
                  { staticClass: "py-1" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Diagnosis",
                              "label-for": "diagnosis",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "diagnosisIds",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("infinit-select", {
                                          ref: "diag",
                                          attrs: {
                                            id: "diagnosis",
                                            multiple: true,
                                            includeIds:
                                              _vm.formData.diagnosisIds,
                                            repo: _vm.diagRepo,
                                            label: "code",
                                          },
                                          model: {
                                            value: _vm.formData.diagnosisIds,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "diagnosisIds",
                                                $$v
                                              )
                                            },
                                            expression: "formData.diagnosisIds",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              _vm._s(errors[0]) +
                                                "\n\n\n            "
                                            ),
                                          ]
                                        ),
                                        errors[0]
                                          ? _c(
                                              "tooltip",
                                              {
                                                attrs: {
                                                  classes: "drop-theme-arrows",
                                                  position: "bottom left",
                                                  openOn: "click",
                                                  header: "Issue Encountered",
                                                },
                                              },
                                              [
                                                _c(
                                                  "small",
                                                  {
                                                    attrs: {
                                                      slot: "drop-trigger",
                                                    },
                                                    slot: "drop-trigger",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticStyle: {
                                                          "text-decoration":
                                                            "underline",
                                                        },
                                                        attrs: { href: "#" },
                                                      },
                                                      [_vm._v("Details")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: {
                                                      slot: "drop-content",
                                                    },
                                                    slot: "drop-content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                errors[0]\n              "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v("Procedures for this claim"),
                ]),
                _vm._l(_vm.formData.procedures, function (procedure, index) {
                  return [
                    _c(
                      "div",
                      [
                        _c("Procedure", {
                          attrs: {
                            "procedure-code": procedure.procedureCodeId,
                            "date-of-service": procedure.dateOfService,
                            "proc-units": procedure.units,
                            amount: procedure.chargeAmount,
                            "place-of-service": procedure.placeOfServiceId,
                            modifiers: procedure.modifierIds,
                            "diag-pointers": procedure.diagPointers,
                            "diagnosis-pointers-options": _vm.diags
                              ? _vm.diags.insuranceOptions.filter(function (
                                  diag
                                ) {
                                  return _vm.formData.diagnosisIds.includes(
                                    diag.id
                                  )
                                })
                              : [],
                            index: index,
                          },
                          on: {
                            "update:procedureCode": function ($event) {
                              return _vm.$set(
                                procedure,
                                "procedureCodeId",
                                $event
                              )
                            },
                            "update:procedure-code": function ($event) {
                              return _vm.$set(
                                procedure,
                                "procedureCodeId",
                                $event
                              )
                            },
                            "update:dateOfService": function ($event) {
                              return _vm.$set(
                                procedure,
                                "dateOfService",
                                $event
                              )
                            },
                            "update:date-of-service": function ($event) {
                              return _vm.$set(
                                procedure,
                                "dateOfService",
                                $event
                              )
                            },
                            "update:procUnits": function ($event) {
                              return _vm.$set(procedure, "units", $event)
                            },
                            "update:proc-units": function ($event) {
                              return _vm.$set(procedure, "units", $event)
                            },
                            "update:amount": function ($event) {
                              return _vm.$set(procedure, "chargeAmount", $event)
                            },
                            "update:placeOfService": function ($event) {
                              return _vm.$set(
                                procedure,
                                "placeOfServiceId",
                                $event
                              )
                            },
                            "update:place-of-service": function ($event) {
                              return _vm.$set(
                                procedure,
                                "placeOfServiceId",
                                $event
                              )
                            },
                            "update:modifiers": function ($event) {
                              return _vm.$set(procedure, "modifierIds", $event)
                            },
                            "update:diagPointers": function ($event) {
                              return _vm.$set(procedure, "diagPointers", $event)
                            },
                            "update:diag-pointers": function ($event) {
                              return _vm.$set(procedure, "diagPointers", $event)
                            },
                            remove: function ($event) {
                              return _vm.removeProcedure(index)
                            },
                          },
                        }),
                        _vm._l(
                          _vm.procedureErrors[index],
                          function (error, indexc) {
                            return [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          attrs: {
                                            variant: "danger",
                                            show: "",
                                            dismissible: "",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(error) +
                                                "\n                "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          }
                        ),
                        _c(
                          "b-row",
                          [
                            _vm._l(
                              _vm.procedureWarnings[index],
                              function (warning, indexc) {
                                return [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          attrs: {
                                            variant: "warning",
                                            show: "",
                                            dismissible: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(warning) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      2
                    ),
                  ]
                }),
                _c(
                  "b-row",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "md", variant: "primary", pill: "" },
                            on: { click: _vm.addProcedure },
                          },
                          [_vm._v("+ Add Procedure")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "9" } }),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      size: "md",
                                      variant: "outline-primary",
                                      block: "",
                                    },
                                    on: { click: _vm.submitForm },
                                  },
                                  [_vm._v("Clear")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      size: "md",
                                      variant: "primary",
                                      block: "",
                                    },
                                    on: { click: _vm.submitForm },
                                  },
                                  [_vm._v("Submit")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }