var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("span", { ref: "dropTrigger" }, [_vm._t("drop-trigger")], 2),
    _c("div", { ref: "dropContent", staticClass: "container-fluid" }, [
      _c(
        "h4",
        { staticClass: "header row py-1 px-1" },
        [
          _vm._t("header", function () {
            return [_vm._v(_vm._s(_vm.header))]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "row px-1 py-1" }, [_vm._t("drop-content")], 2),
      _c(
        "div",
        { staticClass: "row justify-content-end" },
        [
          _c(
            "b-button",
            {
              staticClass: "col-2 my-1 mx-1",
              attrs: { variant: "outline-danger", size: "sm" },
              on: { click: _vm.onClose },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }